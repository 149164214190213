@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);

  app-file-upload-dialog {
    .drop-zone {
      border-color: mat.get-color-from-palette($foreground, divider);
    }

    .drop-icon-hint {
      span {
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}

// TODO: MOve this into core
