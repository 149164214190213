@use 'sass:map';
@use '@angular/material' as mat;
@import './flex.scss';

@mixin core-color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  mat-toolbar.toolbar {
    background-color: mat.get-color-from-palette($background, card);
  }

  .mat-mdc-row:hover {
    background-color: mat.get-color-from-palette($background, hover);
  }

  .mdc-tab {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
    &.mdc-tab--active {
      background-color: mat.get-color-from-palette($background, background);
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .mdc-tab__ripple::before,
  .mat-mdc-tab .mat-ripple-element,
  .mat-mdc-tab-header-pagination .mat-ripple-element,
  .mat-mdc-tab-link .mat-ripple-element .mdc-tab__ripple::before {
    background-color: mat.get-color-from-palette($foreground, base) !important;
  }

  .as-split-gutter {
    background-color: mat.get-color-from-palette($foreground, base, if($is-dark-theme, 0.2, 0.04)) !important;
  }

  .green {
    color: mat.get-color-from-palette(mat.$green-palette, 400);
  }

  .red {
    color: mat.get-color-from-palette(mat.$red-palette, 800);
  }

  .yellow {
    color: mat.get-color-from-palette(mat.$yellow-palette, 500);
  }

  .grey {
    color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.5, 0.4));
  }

  .text-subtle {
    color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
  }
}

@mixin core-typography($config) {
  :root,
  body,
  app-root {
    font-family: mat.font-family($config, 'body-1');
  }
}

@mixin core-theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include core-color($theme);
  }
  $typography-config: mat.define-typography-config();
  @if $typography-config != null {
    @include core-typography($typography-config);
  }

  :root {
    --content-margin: 1.34375rem;
    --content-gutter: 1.34375rem;
    --font-mono: 'Cascadia Code', 'Fira Code', Menlo, Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', 'Courier New', Courier, monospace;
    --appbar-height: 24px;
  }

  body,
  app-root {
    width: 100dvw;
    height: 100dvh;
    display: block;
  }

  :root {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
  }

  pre,
  code,
  .font-mono {
    font-family: var(--font-mono) !important;
  }

  mat-toolbar.toolbar {
    height: 80px !important;
    flex-shrink: 0;
    font-size: 16px;
  }

  .form-field-row {
    display: flex;
    flex-direction: row;
    gap: var(--content-gutter);
    padding: 0 var(--content-margin);
  }

  .form-button-row {
    display: flex;
    flex-direction: row;
    gap: calc(var(--content-gutter) / 2);
    justify-content: flex-end;
    align-items: center;
    padding: 0 var(--content-margin);
  }

  body {
    transition:
      background-image 1s ease,
      background-color 1s ease;
    background-image: var(--bg-image, black) !important;
    background-color: var(--bg-color, black) !important;
    background-repeat: var(--bg-repeat, no-repeat) !important;
    background-size: var(--bg-size, auto) !important;
    background-position: var(--bg-pos, center) !important;

    &.bg-fill {
      --bg-size: cover;
    }

    &.bg-tile {
      --bg-repeat: repeat;
    }
  }

  .mat-drawer-container {
    background: unset !important;
  }

  gridster {
    background: unset !important;
  }

  .notification-bottom-margin {
    margin: 20px !important;
  }
}
