// This ports the fxFlex API to utility classes, a la TailwindCSS

@function index-to-rem($index: &) {
  @return if($index, calc($index / 4) * 1rem, 0rem);
}

.flex {
  display: flex;
}

// Flex Basis
// https://tailwindcss.com/docs/flex-basis
$flex-basis: (
  '1\\/2': 50%,
  '1\\/5': 20%,
  '3\\/5': 60%,
  '4\\/5': 80%
);
@each $key, $value in $flex-basis {
  .basis-#{$key} {
    flex-basis: $value;
  }
}

// Flex
// https://tailwindcss.com/docs/flex
.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

// Flex Direction
// https://tailwindcss.com/docs/flex-direction
$flex-directions: (
  'row': row,
  'row-reverse': row-reverse,
  'col': column,
  'col-reverse': column-reverse
);
@each $key, $value in $flex-directions {
  .flex-#{$key} {
    flex-direction: $value;
  }
}

// Gap
// https://tailwindcss.com/docs/gap
@mixin flex-gap($index, $label: &) {
  $label: if($label, $label, $index);
  $value: calc($index / 4) * 1rem;

  .gap-#{$label} {
    gap: $value;
  }

  .gap-x-#{$label} {
    column-gap: $value;
  }

  .gap-y-#{$label} {
    row-gap: $value;
  }
}
@for $i from 0 through 12 {
  @include flex-gap($i);
  @if $i < 2 {
    @include flex-gap($i + 0.25, #{$i}\.25);
    @include flex-gap($i + 0.5, #{$i}\.5);
    @include flex-gap($i + 0.75, #{$i}\.75);
  } @else if $i < 4 {
    @include flex-gap($i + 0.5, #{$i}\.5);
  }
}

// Justify Content
// https://tailwindcss.com/docs/justify-content
$flex-justify-content: (
  'normal': normal,
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'between': space-between,
  'around': space-around,
  'evenly': space-evenly,
  'stretch': stretch
);
@each $key, $value in $flex-justify-content {
  .justify-#{$key} {
    justify-content: $value;
  }
}

// Justify Self
// https://tailwindcss.com/docs/justify-self
$flex-justify-self: (
  'auto': auto,
  'start': start,
  'end': end,
  'center': center,
  'stretch': stretch
);
@each $key, $value in $flex-justify-self {
  .justify-self-#{$key} {
    justify-self: $value;
  }
}

// Align Content
// https://tailwindcss.com/docs/align-content
$flex-align-content: (
  'normal': normal,
  'center': center,
  'start': flex-start,
  'end': flex-end,
  'between': space-between,
  'around': space-around,
  'evenly': space-evenly,
  'baseline': baseline,
  'stretch': stretch
);
@each $key, $value in $flex-align-content {
  .content-#{$key} {
    align-content: $value;
  }
}

// Align Items
// https://tailwindcss.com/docs/align-items
$flex-align-items: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'baseline': baseline,
  'stretch': stretch
);
@each $key, $value in $flex-align-items {
  .items-#{$key} {
    align-items: $value;
  }
}
