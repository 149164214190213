@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  app-project-board {
    .track {
      stroke-width: 1px;
      stroke: black;
      fill: mat.get-color-from-palette($primary, lighter, 0.25);
    }

    [project-board-node] {
      &.terminal > rect {
        fill: mat.get-color-from-palette($primary);
        stroke: mat.get-color-from-palette($primary);
      }

      .state-icon {
        fill: mat.get-color-from-palette($primary);
      }
    }

    [project-board-arrow]:not(.fail) {
      path {
        stroke: mat.get-color-from-palette($primary);
      }

      polygon {
        fill: mat.get-color-from-palette($primary);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
