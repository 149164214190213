@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  app-query-builder {
    $divider-color: mat.get-color-from-palette($foreground, divider);

    .connector {
      &::before,
      &::after {
        border-color: $divider-color;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
