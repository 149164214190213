@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $background: map.get($config, background);

  app-widget-titlebar {
    & {
      background-color: mix(mat.get-color-from-palette($background, card), black, if($is-dark-theme, 60%, 95%));
    }

    button.close,
    button.maximize,
    button.restore {
      color: if($is-dark-theme, #ccc, rgba(0, 0, 0, 0.6));

      &:hover {
        background-color: mix(mat.get-color-from-palette($background, card), black, if($is-dark-theme, 60%, 95%));
        color: if($is-dark-theme, #ccc, black);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
