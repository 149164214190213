@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $foreground: map.get($config, foreground);

  app-palette-input {
    .swatch {
      &:hover:after {
        border-color: mat.get-color-from-palette($foreground, hint-text, 0.25);
      }

      &.active:after {
        border-color: mat.get-color-from-palette($primary);
      }
    }

    .separator {
      background-color: mat.get-color-from-palette($foreground, hint-text, 0.25);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
