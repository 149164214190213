@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  app-vault {
    .stacked-spinner {
      &[color='active'] {
        --mdc-circular-progress-active-indicator-color: #{mat.get-color-from-palette(mat.$green-palette, 400)};
      }
      &[color='warn'] {
        --mdc-circular-progress-active-indicator-color: #{mat.get-color-from-palette(mat.$orange-palette, 400)};
      }
      &[color='error'] {
        --mdc-circular-progress-active-indicator-color: #{mat.get-color-from-palette(mat.$red-palette, 400)};
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
