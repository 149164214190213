@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  app-host-timeline {
    .point.active {
      border-color: mat.get-color-from-palette(mat.$green-palette, 400) !important;
      background-color: mat.get-color-from-palette(mat.$green-palette, 400) !important;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
