@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $foreground: map.get($config, foreground);

  app-definition {
    .panel-head {
      background-color: mat.get-color-from-palette($foreground, base, if($is-dark-theme, 0.1, 0.04));
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  app-definition {
    mas-split-button {
      span.mdc-button__label {
        display: flex;
        align-items: center;
      }
    }
  }
}
