@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  app-chat-widget {
    app-widget-titlebar {
      margin-bottom: 0px;
    }

    .model-selector {
      color: mat.get-color-from-palette($foreground, hint-text, 0.75);

      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }
    }

    .chat-history-list > li:hover {
      background-color: mat.get-color-from-palette($background, hover);
    }

    .clear-button {
      color: mat.get-color-from-palette($foreground, hint-text, 0.75);
    }

    .prompt-box {
      .mat-mdc-form-field-focus-overlay {
        display: none;
      }
    }

    .chat-history-container {
      background-color: mix(mat.get-color-from-palette($background, card), black, if($is-dark-theme, 60%, 95%));

      .block-header {
        background-color: mix(mat.get-color-from-palette($background, card), black, if($is-dark-theme, 60%, 95%));
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  app-chat-widget {
    .mdc-text-field {
      border-radius: 26px !important;
      padding-right: 0.5rem !important;
    }

    .mdc-line-ripple::after,
    .mdc-line-ripple::before {
      border: none !important;
    }
  }

  .model-selector-overlay-pane {
    width: 340px !important;
  }
}
