@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);

  app-deploy-card {
    .status-avatar {
      color: if($is-dark-theme, white, black);

      &.active {
        background-color: mat.get-color-from-palette(mat.$green-palette, 400);
      }

      &.warn {
        background-color: mat.get-color-from-palette(mat.$orange-palette, 400);
      }

      &.error {
        background-color: mat.get-color-from-palette(mat.$red-palette, 400);
      }
    }

    .models-panel {
      .mat-expanded .mat-expansion-panel-content {
        padding-bottom: 5px;
      }

      .mat-expansion-panel-content {
        padding: 0px 5px;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
