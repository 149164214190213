@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  app-service-status-icon {
    color: mat.get-color-from-palette($background, card);

    &.active {
      background-color: mat.get-color-from-palette(mat.$green-palette, 400);
    }

    &.warn {
      background-color: mat.get-color-from-palette(mat.$orange-palette, 400);
    }

    &.error {
      background-color: mat.get-color-from-palette(mat.$red-palette, 400);
    }

    &.unknown {
      background-color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.3, 0.26));
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
