@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  app-asset {
    .redButton {
      // TODO: Figure out what this is and how to style accordingly
      min-width: 100px;
      color: white;
      background-color: red;
    }

    .warning {
      // TODO: Figure out what this is and how to style accordingly
      background-color: lightcoral;
      color: white;
    }

    .inline-icon {
      // TODO: Figure out what this is and how to style accordingly
      border-color: black;
    }

    #recent-build-label {
      // NOTE: This is described as stored $label-color in @angular/material/.../form-field-theme.scss
      color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
    }

    #recent-build-container button {
      // NOTE: This is described as stored $underline-color in @angular/material/.../form-field-theme.scss
      background-color: mat.get-color-from-palette($background, hover);
      border-bottom-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
