/* You can add global styles to this file, and also import other style files */

@use 'sass:map';
@use '@angular/material' as mat;
@use '@material-spirit/ngx-split-button' as mas-split-button;
@use './styles/index' as lit;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@include mat.all-component-typographies();
@include mat.core();
@include mas-split-button.core-styles;

@include mat.core-theme(lit.$light-theme);
@include mat.all-component-themes(lit.$light-theme);
@include lit.core-theme(lit.$light-theme);
@include lit.all-component-themes(lit.$light-theme);
@include mas-split-button.theme(lit.$light-theme);

:root.dark {
  @include mat.core-color(lit.$dark-theme);
  @include mat.all-component-colors(lit.$dark-theme);
  @include lit.core-color(lit.$dark-theme);
  @include lit.all-component-colors(lit.$dark-theme);
  @include mas-split-button.theme(lit.$dark-theme);
}

@if lit.$has-custom-theme {
  :root.custom {
    @include mat.core-color(lit.$custom-theme);
    @include mat.all-component-colors(lit.$custom-theme);
    @include lit.core-color(lit.$custom-theme);
    @include lit.all-component-colors(lit.$custom-theme);
    @include mas-split-button.theme(lit.$custom-theme);
  }
}

body,
html {
  overflow-y: hidden;
  height: 100%;
  margin: 0;
}

.error {
  background-color: rgb(126, 4, 4);
  color: white;
}

.error button {
  background-color: white;
  color: rgb(126, 4, 4);
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.padded {
  padding: 10px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 10px;
  padding-left: 15px;
  list-style: none;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '/';
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #282c34;
  color: #abb2bf;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #c678dd;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #d19a66;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

app-chart,
app-preview,
app-train-run,
app-users,
app-data-label,
app-train-notes-editor,
app-train-notes-dialog,
app-projects,
app-home,
app-audit-data,
app-audit-dashboard,
app-audit-video,
app-label-video,
app-label-stats,
app-label-audio,
app-label-image,
app-train-edit,
app-label-document,
app-label-sheet,
app-label-eeg,
app-data-coverage,
app-audit-summary {
  width: 100%;
  height: calc(100% - 50px);
  display: block;
  min-height: 800px;
}

app-train-library {
  width: 100%;
  min-height: 800px;
}

.no-checkbox > .mat-pseudo-checkbox {
  display: none !important;
}

.mat-drawer,
.mat-drawer-inner-container {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.mouse-per-line text {
  font-weight: 700 !important;
  font-size: 12px !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #eee;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #aaa;
}

.white {
  color: white;
}

.avatar-container {
  display: inline-block;
  padding: 2px;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  -webkit-touch-callout: none; /* iOS Safari */
}

mat-drawer-content {
  overflow: hidden !important;
}

.mat-grid-tile-content {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.drop-zone {
  width: 500px;
  height: 300px;
  margin: auto;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.no-overflow-dialog-container .mat-mdc-dialog-container {
  overflow-y: hidden;
}

gridster .gridster-column {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

gridster .gridster-row {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.explore-data-point .mat-mdc-dialog-container {
  background-color: #1e1e1e;
}
