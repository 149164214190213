@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  app-home {
    .label {
      color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
    }

    .dashboard-card-content a {
      color: mat.get-color-from-palette($primary, if($is-dark-theme, lighter, default));
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
